<template>
  <div
    v-if="job"
    class="w-full py-8 mx-auto"
  >
    <header class="max-w-screen-xl px-6 pb-8 mx-auto">
      <div>
        <router-link
          :to="jobProfileLink"
          class="inline-block mb-2 text-xs tracking-wider text-gray-600 uppercase duration-150 ease-out hover:text-secondary"
        >
          <span>{{ job.name }}</span>
        </router-link>
        <h1 class="text-2xl leading-tight">
          {{ pageTitle }}
        </h1>
      </div>
    </header>

    <div class="mb-6 border-b-2">
      <div class="flex justify-between max-w-screen-xl px-6 mx-auto">
      </div>
    </div>

    <div
      v-if="!processingCandidates"
      class="max-w-screen-xl px-6 mx-auto"
    >
      <div class="p-6 bg-white rounded-lg shadow">
        <div
          v-if="$usesFeature('classic-jobs')"
          class="mb-8"
        >
          <h2 class="text-xl font-medium">
            Tests
          </h2>
          <p class="mt-2 mb-4">
            Which tests would you like to include?
          </p>

          <section
            v-if="haveExamSuitesLoaded"
            class="grid p-4 bg-gray-100 rounded-lg md:grid-cols-2 lg:grid-cols-3"
          >
            <div
              v-for="examSuite in examSuites"
              :key="examSuite.slug"
              class="col"
            >
              <h4 class="text-lg">
                {{ examSuite.name }}
              </h4>
              <ul class="mt-2">
                <li
                  v-for="exam in examSuite.exams"
                  :key="exam.slug"
                  class="items-center px-2 py-2 sm:flex"
                >
                  <label>
                    <input
                      v-model="chosenExams"
                      :value="exam.slug"
                      type="checkbox"
                      class="form-checkbox"
                    >
                    <span
                      class="ml-2 text-gray-800"
                    >
                      {{ exam.name }}
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          </section>
          <div v-else>
            <Loader />
          </div>

          <template v-if="customExams.length">
            <h2 class="mt-8 mb-4">
              Custom Tests
            </h2>

            <section
              class="max-w-2xl p-4 bg-gray-100 rounded-lg"
            >
              <ul
                class="grid -mx-2 md:grid-cols-2"
              >
                <li
                  v-for="exam in customExams"
                  :key="exam.slug"
                  class="items-center px-2 py-2 sm:flex"
                >
                  <label>
                    <input
                      v-model="chosenExams"
                      :value="exam.slug"
                      type="checkbox"
                      class="form-checkbox"
                    >
                    <span
                      class="ml-2 text-gray-800"
                    >
                      {{ exam.name }}
                    </span>
                  </label>
                </li>
              </ul>
            </section>
          </template>

          <template v-if="assignableExams.length">
            <h2 class="mt-8 mb-4">
              Special Tests
            </h2>

            <section
              class="max-w-2xl p-4 bg-gray-100 rounded-lg"
            >
              <ul
                class="grid -mx-2 md:grid-cols-2"
              >
                <li
                  v-for="exam in assignableExams"
                  :key="exam.slug"
                  class="items-center px-2 py-2 sm:flex"
                >
                  <label>
                    <input
                      v-model="chosenExams"
                      :value="exam.slug"
                      type="checkbox"
                      class="form-checkbox"
                    >
                    <span
                      class="ml-2 text-gray-800"
                    >
                      {{ exam.name }}
                    </span>
                  </label>
                </li>
              </ul>
            </section>
          </template>
        </div>

        <div>
          <h2 class="text-xl font-medium">
            Candidates
          </h2>
          <p class="mt-2 mb-8">
            Which candidates would you like to add?
          </p>
          <form
            novalidate="novalidate"
            @submit.prevent
          >
            <div class="items-end justify-between w-full space-y-6 md:flex md:space-x-6 md:space-y-0">
              <label class="block text-sm md:w-1/5">
                <span class="text-gray-800">First Name</span>
                <input
                  v-model="$v.candidate.firstName.$model"
                  class="block w-full mt-1 bg-gray-100 form-input"
                  autocomplete="given-name"
                >
                <ErrorsInline v-if="hasErrors">
                  <span v-if="!$v.candidate.firstName.required">This field is required</span>
                </ErrorsInline>
              </label>

              <label class="block text-sm md:w-1/5">
                <span class="text-gray-800">Last Name</span>
                <input
                  v-model="$v.candidate.surname.$model"
                  class="block w-full mt-1 bg-gray-100 form-input"
                  autocomplete="family-name"
                >
                <ErrorsInline v-if="hasErrors">
                  <span v-if="!$v.candidate.surname.required">This field is required</span>
                </ErrorsInline>
              </label>

              <label class="block text-sm md:w-1/5">
                <span class="text-gray-800">Email</span>
                <input
                  v-model="$v.candidate.email.$model"
                  type="email"
                  class="block w-full mt-1 bg-gray-100 form-input"
                  autocomplete="email"
                >
                <ErrorsInline v-if="hasErrors">
                  <span v-if="!$v.candidate.email.required">This field is required</span>
                  <span v-if="!$v.candidate.email.email">Needs to be a valid email address</span>
                </ErrorsInline>
              </label>

              <div class="flex items-center w-2/5">
                <BaseButton
                  class="flex-shrink-0"
                  variant="inverse"
                  @click="addCandidate"
                >
                  Add candidate
                </BaseButton>

                <span class="mx-2 text-sm text-gray-600">or</span>

                <UploadCSVButton
                  class="flex-shrink-0"
                  @uploadedCandidates="addCandidatesToPreview($event)"
                />
              </div>
            </div>
          </form>
        </div>

        <div class="mt-12">
          <div class="flex items-center justify-end w-full mb-4">
            <a
              v-if="candidates.length"
              href="javascript:;"
              class="text-sm text-red-500 duration-150 hover:text-red-400"
              @click="deleteAllCandidates()"
            >
              <span class="hidden sm:inline-block">Delete all</span>
              <Icon
                view-box="0 0 24 24"
                class="w-4 h-4 ml-1"
              >
                <Bin />
              </Icon>
            </a>
          </div>

          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden border-b border-gray-100">
                  <table class="min-w-full border border-gray-100 divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          class="relative px-6 py-3"
                        >
                          <span
                            class="sr-only"
                          >Edit</span>
                        </th>
                        <th
                          scope="col"
                          class="relative px-6 py-3"
                        >
                          <span
                            class="sr-only"
                          >Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="!candidates.length">
                        <tr
                          v-for="(row, index) in 5"
                          :key="index"
                          :class="{'bg-white': index % 2 === 0, 'bg-gray-100': index % 2 !== 0 }"
                        >
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                          <td class="px-6 py-4 whitespace-nowrap"></td>
                        </tr>
                      </template>
                      <JobInviteRow
                        v-for="(candidate, index) in candidates"
                        :key="index"
                        :row-index="index"
                        :rows-editing="rowsEditing"
                        :candidate="candidate"
                        :class="{'bg-white': index % 2 === 0, 'bg-gray-100': index % 2 !== 0 }"
                        @editing="editRow($event)"
                        @delete-row="deleteRow($event)"
                        @submit-row="stopEditingRow($event)"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative pt-12 text-right">
          <BaseButton
            class="flex-shrink-0"
            :loading="loading"
            @click="inviteCandidates"
          >
            {{ submitButtonLabel }}
            <template slot="iconRight">
              <Icon
                view-box="0 0 24 24"
                class="w-6 h-6 text-white fill-none"
              >
                <Send stroke-width="1.5" />
              </Icon>
            </template>
          </BaseButton>

          <ErrorsInline
            v-if="inviteError || $v.candidates.$anyError"
            class="right-0"
          >
            {{ inviteError }}
          </ErrorsInline>

          <div v-if="errors.length">
            <ErrorsInline
              v-for="(error, index) in errors"
              :key="index"
              class="relative"
            >
              {{ error }}
            </ErrorsInline>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="processingCandidates"
      class="max-w-screen-xl px-6 mx-auto"
    >
      <div class="p-6 bg-white rounded-lg shadow">
        <div
          class="mb-8 text-center"
        >
          <h2 class="text-xl font-medium">
            Sending tests
          </h2>
          <div class="my-8">
            <Loader />
          </div>
          <p class="mt-2 mb-4">
            Processing {{ processingBatchFrom }} to {{ processingBatchTo }} of {{ candidates.length }} candidates&hellip;
          </p>
          <p class="mt-2 mb-4">
            It may take some time to process, please leave this window open
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bin from '@components/Icons/Bin'
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import JobInviteRow from '@components/Jobs/JobInviteRow'
import Loader from '@components/Loader'
import Send from '@components/Icons/Send'
import UploadCSVButton from '@components/Jobs/UploadCSVButton'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import organisationCandidatesApi from '@api/organisationCandidates'

export default {
  components: {
    Bin,
    ErrorsInline,
    Icon,
    JobInviteRow,
    Loader,
    Send,
    UploadCSVButton
  },

  mixins: [validationMixin],

  props: {
    job: {
      type: Object,
      default: null
    },
    actionVerb: {
      type: String,
      default: 'invite'
    }
  },

  data() {
    return {
      loading: false,
      rowsEditing: [],

      // Batch processing
      processingBatch: 0,
      processingCandidates: false,
      candidatesPerBatch: 50,
      processingBatchErrors: [],

      inviteError: null,
      // Manual Adding of candidate form
      hasErrors: false,
      errors: [],

      candidates: [],

      candidate: {
        firstName: null,
        surname: null,
        email: null
      },

      chosenExams: [],

      submitSuccess: false

    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      haveExamSuitesLoaded: 'exams/haveExamSuitesLoaded',
      haveExamsLoaded: 'exams/haveExamsLoaded',
      examSuites: 'exams/examSuites',
      customExams: 'exams/exams',
      assignableExams: 'organisations/assignableExams'
    }),

    /**
     * @return {Boolean}
     */
    isClean() {
      return !this.$v.candidate.$anyDirty
    },

    /**
     * @return {Object}
     */
    jobProfileLink() {
      return { name: 'client-assessments-show', params: { id: this.job.uuid } }
    },

    /**
     * @return {string}
     */
    pageTitle() {
      if (this.actionVerb === 'add') {
        return 'Add candidates'
      }

      return 'Invite'
    },

    submitButtonLabel() {
      if (this.actionVerb === 'add') {
        return 'Send tests'
      }

      return 'Invite Candidates'
    },

    candidateBatches() {
      if (this.candidates.length === 0) {
        return null
      }

      return Math.ceil(this.candidates.length / this.candidatesPerBatch)
    },

    /**
     * @return {Number}
     */
    processingBatchFrom() {
      return this.processingBatch * this.candidatesPerBatch - this.candidatesPerBatch + 1
    },

    /**
     * @return {Number}
     */
    processingBatchTo() {
      const to = this.processingBatch * this.candidatesPerBatch + 1

      if (this.candidates.length < to) {
        return this.candidates.length
      }
      return to
    }
  },

  validations: {
    candidate: {
      firstName: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      }
    },

    candidates: {
      $each: {
        firstName: {
          required
        },
        surname: {
          required
        },
        email: {
          required,
          email
        }
      }
    }
  },

  watch: {
    candidates() {
      if (this.candidates.length) {
        this.inviteError = null
      }
    },

    chosenExams() {
      if (this.chosenExams.length) {
        this.inviteError = null
      }
    }
  },

  created() {
    if (!this.haveExamSuitesLoaded && this.$usesFeature('classic-jobs')) {
      this.$store.dispatch('exams/getExamSuites')
    }
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  methods: {
    addCandidate() {
      this.$v.candidate.$touch()
      this.hasErrors = this.$v.candidate.$anyError
      if (this.hasErrors || this.isClean) {
        this.loading = false
        return
      }

      this.candidates.push(this.candidate)
      // Clear Inputs
      this.candidate = {
        firstName: null,
        surname: null,
        email: null
      }
    },

    editRow(row) {
      this.rowsEditing.push(row)
    },

    deleteRow(rowIndex) {
      this.candidates = this.candidates.filter((_, index) => index !== rowIndex)
    },

    stopEditingRow(row) {
      // Update candidate in array
      this.candidates.splice(row.index, 1, row.candidate)
      this.rowsEditing = this.rowsEditing.filter(item => item !== row.index)
    },

    deleteAllCandidates() {
      this.candidates = []
    },

    addCandidatesToPreview(uploadedCandidates) {
      this.candidates = [...this.candidates, ...uploadedCandidates]
    },

    inviteCandidates() {
      if (!this.candidates.length) {
        this.inviteError = 'You need to add some candidates first'
        return
      }

      if (this.rowsEditing.length) {
        this.inviteError = 'Please finish editing before submitting'
        return
      }

      if (this.$usesFeature('classic-jobs') && !this.chosenExams.length) {
        this.inviteError = 'Please select at least one test'
        return
      }

      if (!this.candidates.length) {
        this.inviteError = 'You need to add some candidates first'
        return
      }

      this.$v.candidates.$touch()
      if (this.$v.candidates.$anyError) {
        this.inviteError = 'Please check all names and emails for errors'
        return
      }

      this.loading = true
      this.processingCandidates = true

      this.startBatchInvite(1)
    },

    /**
     * Trigger the start of the invitation
     */
    startBatchInvite(batchNumber) {
      this.processingBatch = batchNumber

      const candidatesInBatch = this.candidatesInBatch(batchNumber)

      if (candidatesInBatch.length === 0) {
        console.log('Finished running batches')

        this.submitSuccess = true
        this.$emit('candidatesInvited')

        this.$store.dispatch('jobs/getJobs')

        // Remove page refresh warning
        window.removeEventListener('beforeunload', this.launchReloadWarning)

        if (this.$usesFeature('classic-jobs')) {
          this.$router.push(this.jobProfileLink)
        } else {
          this.$router.push({ ...this.jobProfileLink, query: { tab: 'invited' } })
        }

        return
      }

      console.log('Starting batch', batchNumber)

      this.sendInvitations(candidatesInBatch)
        .then(() => {
          batchNumber = batchNumber + 1
          this.startBatchInvite(batchNumber)
        })
    },

    /**
     * Send the invitations
     */
    sendInvitations(candidates) {
      let candidatesAndJob = {
        candidates,
        jobUuid: this.job.uuid
      }

      if (this.$usesFeature('classic-jobs')) {
        candidatesAndJob = { ...candidatesAndJob, examSlugs: this.chosenExams }
      }

      const endPoint = (organisationId, candidatesAndJob) => {
        if (this.$usesFeature('classic-jobs')) {
          return organisationCandidatesApi.uploadCandidatesBatch(organisationId, candidatesAndJob)
        }
        return organisationCandidatesApi.uploadCandidates(organisationId, candidatesAndJob)
      }

      return endPoint(this.organisationId, candidatesAndJob)
        .then(response => {
          return response
        })
        .catch(error => {
          if (error.response && error.response.data.errors) {
            this.processingBatchErrors.push(error.response.data.errors)
            return
          }
          throw error
        })
    },

    /**
     * Returns candidates for a certain batch number
     *
     * @return {Array}
     */
    candidatesInBatch(batchNumber) {
      if (this.candidates.length === 0) {
        return null
      }

      let end = this.candidatesPerBatch * batchNumber
      let start = end - this.candidatesPerBatch

      return this.candidates.slice(start, end)
    },

    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    },

    /**
     * Reset the exams so that it’s not confusing that you could possibly
     * add both types of levels.
     *
     * @return void
     */
    levelChanged() {
      this.chosenExams = []
    }
  }
}
</script>
