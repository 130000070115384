<template>
  <div>
    <div
      v-if="job"
      class="bg-gray-100 flex-grow"
    >
      <JobInvite
        :action-verb="actionVerb"
        :job="job"
        @candidatesInvited="candidatesInvited = true"
      />
    </div>
    <div
      v-else
      class="flex-grow my-12"
    >
      <Loader />
    </div>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div
        class="border-b-2 bg-white"
      >
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Are you sure you want to continue?
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div class="p-6 space-y-8 bg-gray-100">
        <p
          v-if="actionVerb === 'invite'"
        >
          You haven’t invited any candidates yet.
        </p>
        <p
          v-else-if="actionVerb === 'add'"
        >
          You haven’t sent any tests yet.
        </p>

        <div class="flex justify-end mt-2 space-x-6">
          <BaseButton
            variant="ghost"
            class="bg-transparent"
            @click="close"
          >
            Continue adding candidates
          </BaseButton>
          <BaseButton @click="continueToRoute">
            Discard changes
          </BaseButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import JobInvite from '@components/Jobs/JobInvite'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'

import jobsApi from '@api/jobs'

export default {
  components: {
    Icon,
    JobInvite,
    Loader,
    Modal,
    Plus
  },

  data() {
    return {
      job: null,
      to: null,
      modalOpen: false,
      candidatesInvited: false
    }
  },

  computed: {
    /**
     * @return {string}
     */
    actionVerb() {
      if (this.$usesFeature('classic-jobs')) {
        return 'add'
      }
      return 'invite'
    }
  },

  watch: {
    modalOpen(val) {
      // Handles outside modal click
      if (!val) {
        this.to = null
      }
    }
  },

  created() {
    this.getJob()
  },

  beforeRouteLeave(to, from, next) {
    if (this.candidatesInvited) {
      next()
    }

    if (this.to) {
      next()
    } else {
      this.to = to
      this.modalOpen = true
    }
  },

  methods: {
    getJob() {
      return jobsApi.job(this.$route.params.job)
        .then(response => {
          this.job = response.job
        })
        .catch(error => {
          throw error
        })
    },

    close() {
      this.modalOpen = false
      this.to = null
    },

    continueToRoute() {
      this.modalOpen = false
      this.$router.push(this.to)
    }
  }
}
</script>
